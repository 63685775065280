import logo from './logo.png';
import styled, { keyframes } from 'styled-components';
import dexscreener from './dexscreener.png';
import x from './x.png';
import telegram from './telegram.png';
import { Grid } from '@mui/material';
import meme1 from './meme1.jpg'
import meme2 from './meme2.jpg'
import meme3 from './meme3.jpg'
import meme4 from './meme4.jpg'

import './App.css';

const StyledImage = styled.img`
  margin-top: 20px;
  height: 400px;
`

const StyledIcon = styled.img`
  margin-top: 20px;
  height: 40px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Add transition for smooth animation */

  /* Define hover effect */
  &:hover {
    transform: scale(1.5); /* Increase size by 50% */
  }
`

const StyledText = styled.p`
  font-size: 14px;
`

const StyledPump = styled.p`
  font-size: 18px;
  color: white;
  cursor: pointer;
    &:hover {
    transform: scale(1.5); /* Increase size by 50% */
    underline: true;
  }
`

const StyledMeme = styled.img`
  height: 200px;
  margin-bottom: 20px;

`

function App() {
  return (
    <div className="App">

      <header className="App-header">
        <Grid container direction="row" spacing={5} justifyContent={"center"} alignItems={"center"}>
          <Grid item>
            <StyledIcon src={x} onClick={() => window.open("https://x.com/angrymartinisol", "_blank")}></StyledIcon>
          </Grid>
          <Grid item>
            <StyledIcon src={dexscreener} onClick={() => window.open("", "_blank")}></StyledIcon>
          </Grid>
          <Grid item>
            <StyledIcon src={telegram} onClick={() => window.open("https://t.me/angrymartinisol", "_blank")}></StyledIcon>
          </Grid>
        </Grid>
        <StyledPump>
          PUMP
        </StyledPump>
        <StyledImage src={logo} />


        <StyledText>
          the cat is an angry martini
        </StyledText>

        <Grid container justifyContent={"center"} alignItems={"center"} spacing={2}>
          <Grid item>
            <StyledMeme src={meme1} />
          </Grid>
          <Grid item>
            <StyledMeme src={meme2} />
          </Grid>
          <Grid item>
            <StyledMeme src={meme3} />
          </Grid>
          <Grid item>
            <StyledMeme src={meme4} />
          </Grid>
        </Grid>
      </header>
    </div>
  );
}

export default App;
